import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setAutodetectedLocale } from "./DayjsLocaleResolver";


export type LangCode = "es" | "en" | "pt" | "fr";

export type Language = {
    code: LangCode;
    label: string;
};

export const availableLangs : Array<Language> = [
    {label: "LanguageSelector.SPANISH", code: "es"},
    {label: "LanguageSelector.ENGLISH", code: "en"},
    {label: "LanguageSelector.PORTUGUESE", code: "pt"},
    {label: "LanguageSelector.FRENCH", code: "fr"}
];

export const getLanguageFromCode = (code: string) : Language | undefined => {
    return availableLangs.find((lang: Language) => lang.code === code);
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "es",
        supportedLngs: availableLangs.map((lang) => lang.code),
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: `${import.meta.env.BASE_URL}static/lang/{{lng}}.json`
        },
        detection: {
            order: [ 'localStorage', 'navigator']
        }
    });

i18n.on("languageChanged", (lang)=> {
    setAutodetectedLocale();
    window.document.documentElement.setAttribute("lang", lang);
});

export default i18n;
