import React from "react";
import i18n, { availableLangs, getLanguageFromCode, Language } from "../util/i18n";
import { AppConsumer } from "./AppContext";

type MyProps = Record<string, never>;
type MyState = {
    selectedLang: Language | undefined;
    open: boolean;
};

export default class LanguageSelector extends React.Component<
    MyProps,
    MyState
> {
    private ref: React.RefObject<HTMLDivElement>;
    constructor(props : MyProps) {
        super(props);
        this.state = { open: false, selectedLang: getLanguageFromCode(i18n.language)};
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleClickOutside(event: MouseEvent) {
        if (this.ref.current && !this.ref.current.contains(event.target as Node)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        i18n.on('initialized', () => {
            this.setState({selectedLang: getLanguageFromCode(i18n.language)});
        });
        document.addEventListener("click", this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, true);
    }

    changeLang = (lang: Language) => {
        i18n.changeLanguage(lang.code);
        this.setState({ open: false, selectedLang: lang});
    };
    handleOpenClose = () => {
        this.setState({open: !this.state.open });
    };
    render() {
        return (
            <AppConsumer>
                {({t}) => (
                    <div
                        ref={this.ref}
                        className={
                            "language-selector " +
                            (this.state.open ? "open" : "")
                        }>
                        <button className="tab" onClick={this.handleOpenClose}>
                            <i className="bi bi-globe"></i> {" "} {t(this.state.selectedLang?.label || "LanguageSelector.LANGUAGE")} {this.state.open? <i className="bi bi-caret-down"></i>: <i className="bi bi-caret-right"></i>}
                        </button>
                        <div className="button-container">
                            <ul>
                                {availableLangs.map((lang: Language) => {
                                    return (
                                        <li key={lang.code}>
                                            <button
                                                onClick={() =>
                                                    this.changeLang(lang)
                                                }>
                                                {t(lang.label)}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </AppConsumer>
        );
    }
}
