import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { getCurrentData, getTotalStepCount, getOrder, IRoute } from "../util/RouteHelper";
import { AppContext } from "./AppContext";
import confirm from "../img/confirm.svg";
import { FaCheck } from "react-icons/fa";


const Steps = () => {
    const location = useLocation();
    const ctx = useContext(AppContext);
    const t = ctx.t;
    if (!ctx.state.company)
        return <> </>
    const currData = getCurrentData(ctx, location.pathname);
    const stepCount = getTotalStepCount(ctx);
    const routeOrder = getOrder(ctx)
    const isReserved = location.pathname.includes("done");

    const renderNumber = (idx : number, currData: number) => {
        return idx + 1 >= currData;
    };

    return (
        <>
            {
                ctx.state.company.retroStyle &&
                <>
                    <div className="steps_maxW">
                        <ol className="steps">
                            {routeOrder.filter(route => route.countStep).map((route: IRoute, i: number) => {
                                return (
                                    <li key={i} className={"steps__step steps__step" +
                                        (i + 1 === currData?.idx ? " steps__step steps__step--active" : "")
                                    }>
                                        <div className="steps__number">
                                            <span>{i + 1}</span>
                                        </div>
                                        <p className="steps__title">{t(route.retroTitle)}</p>
                                        {
                                            !(stepCount === i + 1) &&
                                            <span className="steps__separator"></span>
                                        }
                                    </li>
                                );
                            })}

                        </ol>
                    </div>
                    <div className="form-card__header content-box__steps">
                        {
                            !!currData &&
                            <>
                                <div className="form-card__title content-box__steps-title"><i className={'bi ' + currData.icon}></i> {t(currData.title)}</div>
                                <div className="content-box__steps-number">{currData.idx} de {stepCount}</div>
                            </>
                        }
                    </div>
                </>
            }
            {
                isReserved && !ctx.state.company.retroStyle &&
                <>
                    <div className="reservedView__header">
                        <img src={confirm} className="reservedView__header-img" alt="Confirm"/>
                    </div>
                </>
            }
            {currData?.countStep && !ctx.state.company.retroStyle &&
                <>

                    <div className="steps_maxW">
                        <h3>
                            {t(currData.title)}
                        </h3>
                        <ol className="steps">
                            {routeOrder.filter(route => route.countStep).map((_: IRoute, i: number) => {
                                return (
                                    <li key={i} className={"steps__step steps__step" +
                                        (i + 1 === currData?.idx ? " steps__step steps__step--active" : "") + (i + 1 < currData?.idx ? "  steps__step steps__step--finished" : "")
                                    }>
                                        <div className={"steps__number " + (i === routeOrder.length - 2 ? '' : 'steps__number-dot')}>
                                            <span>{renderNumber(i,currData.idx) ? i + 1 : <FaCheck/>}</span>
                                        </div>
                                    </li>
                                );
                            })}

                        </ol>
                    </div>
                </>
            }

        </>
    );
};

export default Steps;
