import axios, { AxiosHeaders, AxiosResponse } from "axios";
import dayjs from "dayjs";
import { Appointment, Company, Schedule, Slot } from "../interfaces/models";

const localUrl = '/mockData';
const serverUrl = "";

const productionApi  = {
    companies: () => {
        return {
            getAll: () => axios.get(`${serverUrl}/api/v1/web/companies`),
            get: (name: string) => axios.get(`${serverUrl}/api/v1/company/resources/${name}`)
        }
    },
    schedules: () => {
        return {
            getAll: (companyId: number) => axios.get(`${serverUrl}/api/v1/web/schedule/company`, {
                params: {companyId, paused: false}
            })
        }
    },
    branches: () => {
        return {
            getAll: (companyId: number) => axios.get(`${serverUrl}/api/v1/web/branches/${companyId}`),
            byScheduleAndDate: (companyName: string, scheduleName: string | undefined, strDate: string) => axios.get(`${serverUrl}/api/v2/web/appointment/availablebranches`, {
                params: {companyName, scheduleName, strDate}
            })
        }
    },
    availability: () => {
        return {
            getInMonth: (scheduleId: number, branchId: number, isoDate: string) =>
                axios.get(`${serverUrl}/api/v1/web/appointment/availabledays?`, {
                    params: {
                        scheduleId,
                        branchId,
                        strDate: isoDate
                    }
                }),
            getInMonthForAllBranches: (companyName: string, scheduleName: string, isoDate: string) =>
                axios.get(`${serverUrl}/api/v2/web/appointment/availabledays/allbranches`, {
                    params: {
                        companyName,
                        scheduleName,
                        strDate: isoDate
                    }
                })
        }
    },
    customerAttributes: () => {
        return {
            getVisible: (schedule: Schedule) => axios.get(`${serverUrl}/api/v1/schedules/${schedule.id}/customerattributes`),
            getObligatory: (schedule: Schedule) => axios.get(`${serverUrl}/api/v1/schedules/${schedule.id}/obligatorycustomerattributes`),
            getFields: (company: Company) => axios.get(`${serverUrl}/api/v2/customerfield/labels/${company.id}`),
            getMaxLength: (company: Company) => axios.get(`${serverUrl}/api/v2/customerfield/maxlength/${company.id}`)
        }
    },
    appointment: () => {
        return {
            uploadFile: (body: FormData, companyName: string) => axios.post(`${serverUrl}/api/v2/web/files/upload/${companyName}`, body, {
                headers: { "Content-Type": "multipart/form-data" }
            }),
            confirm: (body: Appointment) => axios.post(`${serverUrl}/api/v3/web/appointment`, body)
        }
    },
    encryption: () => {
        return {
            getPublicKey: () => axios.get(`${serverUrl}/api/v1/getpublickey`)
        }
    },
    termsAndConditions: (company: Company) => {
        return {
            get: (termsId: number) => axios.get(`${serverUrl}/api/v1/legal/termAndCondition/${company.name}/${termsId}`)
        }
    },
    timezone: () => {
        return {
            get: (branchId: number) => axios.get(`${serverUrl}/api/v1/timezone/${branchId}`),
            company: (companyId: number) => axios.get(`${serverUrl}/api/v1/company/timezone/${companyId}`)
        }
    }
};

const generateSlots = (cantSlots: number, searchDate: Date): Slot[] => {
    const slots: Slot[] = [];
    const today = dayjs();
    for(let i=0; i<cantSlots; i++){
        const day = i%5 + 1;
        const hour = Math.floor(i/5/2) + 9;
        const minute = (Math.floor(i/5) % 2) * 30;
        const date = today.add(day, "day")
            .hour(hour)
            .minute(minute);
        if(!date.isSame(searchDate, 'month'))
            continue;
        const startDate = date.format('YYYY-MM-DDTHH:mm:ssZZ');
        const s: Slot = {
            startDate,
            zonedStartDate: startDate.replace(/\d{2}$/, ":$&"),
            availability: Math.floor(Math.random() * 4),
            defaultDuration: 30,
        };
        slots.push(s);
    }
    return slots;
};

const localApi = {
    companies: () => {
        return {
            getAll: () => axios.get(`${localUrl}/companies.json`),
            get: (name: string) => axios.get(`${localUrl}/companies.json`).then(res => {
                res.data = res.data.find((c:Company) => c.name === name);
                return res;
            })
        }
    },
    schedules: () => {
        return {
            getAll: () => axios.get(`${localUrl}/schedules.json`)
        }
    },
    branches: () => {
        return {
            getAll: () => axios.get(`${localUrl}/branches.json`),
            byScheduleAndDate: () => axios.get(`${localUrl}/branches.json`)
        }
    },
    availability: () => {
        return {
            getInMonth: (_scheduleId: number, _branchId: number, isoDate: string) => new Promise<AxiosResponse>(res => {
                const status=200, statusText="OK";
                const cantSlots = 2*9*5; // 5 days, 9hs per day
                const slots = generateSlots(cantSlots, new Date(isoDate));
                res({
                    data: {slots},
                    status,
                    statusText,
                    headers: {},
                    config: {
                        headers : new AxiosHeaders()
                    }
                });
            }),
            getInMonthForAllBranches: (_companyName: string, _scheduleName: string, isoDate: string) => new Promise<AxiosResponse>(res => {
                const status=200, statusText="OK";
                const cantSlots = 2*9*5; // 5 days, 9hs per day
                const slots = generateSlots(cantSlots, new Date(isoDate));
                res({
                    data: {slots},
                    status,
                    statusText,
                    headers: {},
                    config: {
                        headers : new AxiosHeaders()
                    }
                });
            })
        }
    },
    customerAttributes: () => {
        return {
            getVisible: () => axios.get(`${localUrl}/visibleFields.json`),
            getObligatory: () => axios.get(`${localUrl}/obligatoryFields.json`),
            getFields: () => axios.get(`${localUrl}/customerFields.json`),
            getMaxLength: () => axios.get(`${localUrl}/maxlength.json`)
        }
    },
    appointment: () => {
        return {
            uploadFile: (_body: FormData, _companyName: string) => new Promise<AxiosResponse>((res) => {
                const rndGen = () => Math.random().toString(36).substring(2);
                res({
                    status: 200,
                    statusText: "OK",
                    data: {
                        randomId: rndGen() + rndGen()
                    },
                    headers: {},
                    config: {
                        headers : new AxiosHeaders()
                    }
                })
            }),
            confirm: (body: Appointment) => new Promise<AxiosResponse>((res, rej) => {
                if(body.schedule.captchaValidation && !body.captcha.token){
                    rej({
                        status: 401,
                        statusText: "Unauthorized",
                        data: {
                            error: "No captcha"
                        }
                    });
                    return;
                }
                res({
                    status: 200,
                    statusText: "OK",
                    data: {
                        confirmationType: body.schedule.captchaValidation ? "NONE" : "EMAIL"
                    },
                    headers: {},
                    config: {
                        headers : new AxiosHeaders()
                    }
                })
            })
        }
    },
    encryption: () => {
        return {
            getPublicKey: () => axios.get(`${localUrl}/pubkey`)
        }
    },
    termsAndConditions: (_company: Company) => {
        return {
            get: (_termsId: number) => axios.get(`${localUrl}/termsAndConditions.json`)
        }
    },
    timezone: () => {
        return {
            get: (_branchId: number) => axios.get(`${localUrl}/timezone`),
            company: (_companyId: number) => axios.get(`${localUrl}/timezone`)

        }
    }
};

let api = productionApi;
if (import.meta.env.DEV) api = localApi;

export default api;
