import dayjs from "dayjs";
import i18n from "./i18n";
import "./locale/imports";
import "./locale/dayjsLocaleOverrides";

const DEFAULT_LOCALE = 'es'

const nav_langs = navigator.languages || [navigator.language || DEFAULT_LOCALE];

const autodetect = () => {
    // Find, in order of priority, the first user locale that can be used
    const locale = nav_langs
        .map((lang) => lang.toLowerCase())
        .find((lang) => lang !== undefined)
            || DEFAULT_LOCALE;

    // If detected locale by i18next and findLocale are the same use the region specific
    // eg if i18next detects `es` and findLocale detects `es-MX` use es-MX
    // if region specific is not available dayjs will fallback to the language
    if(locale.startsWith(i18n.language)) {
        return locale;
    }
    return i18n.language;
}

export const setAutodetectedLocale = () => {
    dayjs.locale(autodetect());
}

