
export const isEmpty = (obj: unknown | undefined)
    : obj is (undefined|Record<string, never>) => {
    return !obj || Object.keys(obj as Record<string, unknown>).length ===0;
};

type HasStringInKey<K extends string> = {
    [a in K]: string;
};

export const orderArray = <K extends string, A extends HasStringInKey<K>[]> (arr: A, key: K) => {
    return arr.sort((a, b) => {
        return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
    });
}
