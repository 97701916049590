import React, { useContext, useEffect, useState } from 'react';
import { Company } from '../interfaces/models';
import api from '../requests/api';
import Select, { SingleValue } from 'react-select'
import { AppContext, AppConsumer } from '../components/AppContext';
import { toast } from 'react-toastify';
import { orderArray } from '../util/ObjectUtils';
import { useNavigate } from 'react-router-dom';

interface IState{
    all: Company[],
    loading: boolean
}
const SelectCompany = () => {
    const [state, setState] = useState<IState>({all: [], loading: true});
    const [ selected, setSelected ] = useState<SingleValue<Company>>(null);
    const ctx = useContext(AppContext);
    const t = ctx.t;
    const navigate = useNavigate();
    useEffect(()=>{
        ctx.actions!.selectCompany(undefined);
        api.companies().getAll().then(res => {
            setState({
                all: orderArray(res.data, 'name'),
                loading: false
            });
        }).catch(res => {
            console.error("Error al buscar compañías", res);
            setState({
                all: [],
                loading: false
            });
            toast.error(t("SelectCompany.TOAST_COMPANY_SEARCH_ERROR"));
        });
    }, []);

    const change = async ( comp : SingleValue<Company> ) => {
        setSelected(comp);
        if(!comp) return;
        const company = (await api.companies().get(comp.name)).data as Company;
        await ctx.actions!.selectCompany(company);
        navigate(`/company/${comp.name}`);
    };

    return (
        <AppConsumer>
            {({ t }) => (
                <>
                    <div className="content-box__steps">
                        <div className="content-box__steps-title">{t("SelectCompany.SELECT_COMPANY")}</div>
                    </div>
                    <div className="list-selection">
                        <div>
                            <Select options={state.all}
                                isLoading={state.loading}
                                getOptionLabel={ (op)=>op.name }
                                getOptionValue={ (op)=>op.id+'' }
                                onChange={ change }
                                value={selected}
                            />
                        </div>
                    </div>
                </>
            )}
        </AppConsumer>
    )
};


export default SelectCompany;

