import '../styles/spinner.scss'

interface IProps{
    loading?: boolean;
    color?: string;
    style?: React.CSSProperties;
    scale?: number;
}

const Spinner = (props: IProps) => {
    let {loading, color, style, scale} = props;
    color = color || '#fff';
    style = style || {};
    scale = scale || 1;

    const divStyle = {
        border: `${8*scale}px solid ${color}`,
        borderColor: `${color} transparent transparent transparent`,
        width: `${64*scale}px`,
        height: `${64*scale}px`,
        margin: `${8*scale}`
    };

    style.width = `${80*scale}px`;
    style.height = `${80*scale}px`;

    // Taken from https://loading.io/css/
    return <div className="lds-ring" style={style}>{
        loading && <>
            <div style={divStyle}></div>
            <div style={divStyle}></div>
            <div style={divStyle}></div>
            <div style={divStyle}></div>
        </>
    }
    </div>;
};

export default Spinner;

