import { TFunction } from "i18next";
import { Customer, CustomerFields, CustomerFieldsLabels } from "../interfaces/models";
import { isEmpty } from "./ObjectUtils";
import { toSnakeCase } from "./StringUtils";

export enum EPropTypes {
    number,
    text,
    email,
    alphanumeric,
    alphanumericWithSpace,
    any
}

type Props = {
    readonly [name in keyof Customer]: EPropTypes
};

const props: Props = {
    dni: EPropTypes.number,
    firstName: EPropTypes.text,
    lastName: EPropTypes.text,
    cuit: EPropTypes.number,
    email: EPropTypes.email,
    phone: EPropTypes.number,
    cuil: EPropTypes.number,
    phoneType: EPropTypes.any,
    libretaCivica: EPropTypes.any,
    libretaEnrolamiento: EPropTypes.any,
    passport: EPropTypes.any,
    cedulaIdentidad: EPropTypes.alphanumericWithSpace,
    cedulaDiplomatica: EPropTypes.any,
    cardNumber: EPropTypes.number,
    customerNumber: EPropTypes.any,
    businessName: EPropTypes.any
};

const validations: { readonly [k in EPropTypes]: RegExp} = {
    [EPropTypes.number]: /^\d+$/,
    [EPropTypes.text]: /^[a-z\u00C0-\u017F ]+$/i,
    [EPropTypes.email]: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    [EPropTypes.alphanumeric]: /^[a-zA-Z0-9]+$/,
    [EPropTypes.alphanumericWithSpace]: /^[a-zA-Z0-9 ]+$/,
    [EPropTypes.any]: /^(.)*$/
};

export enum EValidationErr {
    missing_field,
    invalid_field
}

export type ValidationError = {
    key: EValidationErr,
    target: keyof Customer,
    dataType: EPropTypes
}

type obj = Record<string, unknown>

export const validateCustomer = (cust: Customer, obligatoryFields?: CustomerFields, visibleFields?: CustomerFields) : ValidationError[] => {
    if(isEmpty(visibleFields) && isEmpty(obligatoryFields)) return [];

    const errors : ValidationError[]= [];

    const fieldsToCheck = new Set<keyof Customer>();
    Object.keys((obligatoryFields || {}) as obj).forEach(k => fieldsToCheck.add(k as keyof Customer));
    Object.keys((visibleFields || {}) as obj).forEach(k => fieldsToCheck.add(k as keyof Customer));

    fieldsToCheck.forEach(key => {
        const required = obligatoryFields?.[key];
        if(required && !cust[key]){
            errors.push({
                key: EValidationErr.missing_field,
                target: key,
                dataType: props[key] as EPropTypes
            });
        } else if(cust[key]){
            const regex = validations[props[key] as EPropTypes];
            if(!regex.test(cust[key] as string)){
                errors.push({
                    key: EValidationErr.invalid_field,
                    target: key,
                    dataType: props[key] as EPropTypes
                });
            }
        }

    })

    return errors;
}

const priority: {[key in keyof Customer]?: number}= {
    firstName: 1,
    lastName: 2
};

const fieldSorter = (f1: keyof Customer, f2: keyof Customer) => {
    const p1 = priority[f1] || 999, p2 = priority[f2] || 999;
    return p1 - p2;
};

export const getTranslations = (visibleFields: CustomerFields|undefined, fieldLabels: CustomerFieldsLabels|undefined, t: TFunction | ((a: string) =>string)) => {
    if(!visibleFields || !fieldLabels) return [];

    const fields:({ key: keyof CustomerFields, showName: string })[] = [];
    const sortedVisibles = (Object.keys(visibleFields) as (keyof Customer)[])
        .filter(k=>visibleFields[k])
        .sort(fieldSorter);

    for(const key of sortedVisibles){
        const remoteLabel = fieldLabels[toSnakeCase(key)] as string;
        const translationKey = "Fields." + remoteLabel;
        const translation = t(translationKey);
        const showName = translationKey === translation ? remoteLabel : translation;
        fields.push({
            key,
            showName
        });
    }

    return fields;
}

