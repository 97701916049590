import { useContext } from "react";
import { AppContext, AppConsumer } from "../components/AppContext";
import { IGenericProps } from "../interfaces/generics";
import dayjs from "dayjs";
import calendar from "../img/calendar.svg"
import time from "../img/hora.svg"
import branch from "../img/sucursal.svg"
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import withRouteProps from "../util/withRouteProps";

const Reserved = (props: IGenericProps) => {
    const ctx = useContext(AppContext);
    const t = ctx.t;

    const companyName = ctx.state.company?.name || props.params.companyName || '';
    const companyRedirectionRoute = `/company/${companyName}`;

    if (!ctx.state.confirmationType) {
        console.error("Redirecting since no confirmationType was found");
        props.history.push(companyRedirectionRoute);
        return <> </>;
    }
    const titles = {
        EMAIL: t("Reserved.EMAIL_CONFIRM_APPOINTMENT"),
        SMS: t("Reserved.SMS_CONFIRM_APPOINTMENT"),
        NONE: t("Reserved.NONE_TITLES_APPOINTMENT_CONFIRMED")
    }
    const texts = {
        EMAIL: t("Reserved.EMAIL_WILL_RECEIVE_CONFIRMATION"),
        SMS: t("Reserved.SMS_WILL_RECEIVE_CONFIRMATION"),
        NONE: t("Reserved.NONE_TEXTS_APPOINTMENT_CONFIRMED")
    };
    const title = titles[ctx.state.confirmationType];
    const _text = texts[ctx.state.confirmationType];
    const { selectedSchedule, selectedBranch, dateTime, timezone } = ctx.state;


    const redirect = async () => {
        await ctx.actions!.clear();
        if (ctx.state.selectedSchedule?.redirectAfterFinishUrl)
            window.location.href = ctx.state.selectedSchedule.redirectAfterFinishUrl
        else
            props.history.push(companyRedirectionRoute);
    };

    return (
        <AppConsumer>
            {({ t }) => (
                <>
                    <div className="reservedView form-card__content">
                        <div className="reservedView__info">
                            <h1 className="reservedView__info-title"> {title} </h1>
                            <hr />
                            <p className="reservedView__info-text reservedView__info-queue"> {selectedSchedule?.name} </p>

                        </div>
                        <div className="reservedView__resume">
                            <div className="reservedView__resume-row">
                                <img src={calendar} alt="Calendar" />
                                <span>
                                    { dayjs(dateTime).format("L") /* Locale date */ }
                                </span>
                            </div>
                            <div className="reservedView__resume-row">
                                <img src={time} alt="Calendar" />
                                <span>
                                    { dayjs.utc(dateTime).tz(timezone).format("LT") }
                                </span>
                            </div>
                            <div className="reservedView__resume-row">
                                <img src={branch} alt="Calendar" />
                                <span>
                                    {selectedBranch?.name}

                                </span>
                            </div>
                        </div>
                        <div className="reservedView__buttons">
                            <TrackedButton
                                id="finishBtnBack"
                                className="list-selection__button list-selection__button-blue"
                                onClick={redirect}>{t("Reserved.FINISH")}
                            </TrackedButton>
                        </div>
                    </div>

                </>
            )}
        </AppConsumer>
    )
}

export default withRouteProps(withTracker(Reserved));
