import React, { CSSProperties } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { AppContext } from './AppContext';
import Spinner from './Spinner';

interface IViewLoaderState{

}
interface IViewLoaderProps{
    error?: boolean;
    timedOut?: boolean;
    loading: boolean;
    fullscreen?: boolean;
    retry?: ()=>void;
    text?: string;
    children?: ReactElement;
    className?: string;
}

export default class ViewLoader extends React.Component<IViewLoaderProps, IViewLoaderState> {
    componentWillUnmount() {
        this.enableScroll();
    }

    getStyle(): CSSProperties{
        const { fullscreen } = this.props;
        if (fullscreen) {
            this.disableScroll();
            return {
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 9998,
            };
        }

        this.enableScroll();
        return {
            position: 'relative',
        };
    }

    disableScroll() {
        const documentBody = document.body;
        if (documentBody) {
            documentBody.style.setProperty('overflow', 'hidden');
        }
    }

    enableScroll() {
        const documentBody = document.body;
        if (documentBody) {
            documentBody.style.removeProperty('overflow');
        }
    }

    render() {
        const { className, loading, text, error, retry, timedOut, children } = this.props;
        const { t } = this.context;
        if (error) {
            return (
                <div>
                    {t("ViewLoader.ERROR")}
                    <button onClick={retry} className="button button-calm button-block">
                        {t("ViewLoader.RETRY")}
                    </button>
                </div>
            );
        }
        if (timedOut) {
            return (
                <div>
                    {t("ViewLoader.TAKING_LONG_TIME")}
                    <button onClick={retry} className="button button-calm button-block">
                        {t("ViewLoader.RETRY")}
                    </button>
                </div>
            );
        }

        return (
            <div style={this.getStyle()} className={className}>
                {loading && (
                    <div
                        style={{
                            display: 'block',
                            position: 'absolute',
                            zIndex: 657,
                            backgroundColor: 'rgba(255, 255, 255, 0.901961)',
                            margin: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}>

                        <div style={{
                            position: 'absolute',
                            display: 'inline-block',
                            left: '48%',
                            top: '48%',
                            width: '100%',
                            textAlign: 'left',
                        }}>
                            <Spinner color="#26639D" loading/>
                            {text && <p className="deb-loader--text">{text}</p>}
                        </div>
                    </div>
                )}
                {children}
            </div>
        );
    }

    static contextType = AppContext;
    declare context: React.ContextType<typeof AppContext>;
}

