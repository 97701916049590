import React, { ErrorInfo, ReactElement } from 'react';
import './ErrorBoundary.scss';
import { AppConsumer } from "../../components/AppContext";

import img_error from '../../img/img_error.png';

interface IState {
    hasError: boolean,
    error: Error | null,
    errorInfo: ErrorInfo | null,
}

interface IProps {
    children?: ReactElement;
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.log({error, info});

        this.setState({ hasError: true, error: error, errorInfo: info });
    }

    reload() {
        this.setState({
            hasError: false,
            error: null,
            errorInfo: null,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <AppConsumer>
                    {({t}) => (
                        <div className="errorView">
                            <img alt="Error" src={img_error} className={'img-responsive'} />
                            <div className={'errorView__texts'}>
                                {t("ErrorBoundary.UPLOAD_ERROR")}
                                {import.meta.env.DEV && (
                                    <div className={"error-info--item__description"} style={{ marginTop: '10px' }}>
                                        <details style={{ whiteSpace: 'pre-wrap' }}>
                                            {this.state.errorInfo?.componentStack}
                                        </details>
                                        <details style={{ whiteSpace: 'pre-wrap' }}>{this.state.error?.stack}</details>
                                    </div>
                                )}
                            </div>
                            <div className={'error-info--item__button'}>
                                <button
                                    onClick={this.reload.bind(this)}
                                    className="button button-calm button-block">
                                    {t("ErrorBoundary.RELOAD")}
                                </button>
                            </div>
                        </div>
                    )}
                </AppConsumer>
            );
        }
        return this.props.children;
    }
}
