import { Customer, CustomerSnake } from "../interfaces/models";

type SnakeCase<S extends string> = S extends keyof Customer ? keyof CustomerSnake : string;

export const toSnakeCase = <S extends string>(s: S): SnakeCase<S> => {
    return s.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`) as SnakeCase<S>;
};

type CamelCase<S extends string> = S extends keyof CustomerSnake ? keyof Customer : string;

export const toCamelCase = <S extends string>(s: S): CamelCase<S> => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace(/[-_]/g, '')
    }) as CamelCase<S>;
};
