import React, { ChangeEvent } from "react";
import { AppConsumer, AppContext } from "../components/AppContext";
import { IGenericProps } from "../interfaces/generics";
import { getCurrentData, getNextRoute } from "../util/RouteHelper";
import api from "../requests/api";
import { Schedule } from "../interfaces/models";
import { toast } from "react-toastify";
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import { orderArray } from "../util/ObjectUtils";
import withRouteProps from "../util/withRouteProps";
import { Navigate } from "react-router-dom";

interface ISelectScheduleState {
    schedules: Schedule[],
    scheduleNames: Set<string>
    selectedSchedule?: string,
    filter: string
}

type TProps = IGenericProps;

class SelectSchedule extends React.Component<TProps, ISelectScheduleState>{
    constructor(props: TProps) {
        super(props);
        this.next = this.next.bind(this);
        this.goBack = this.goBack.bind(this);
        this.selectQueue = this.selectQueue.bind(this);
        this.state = {
            schedules: [],
            scheduleNames: new Set<string>(),
            filter: ''
        }
    }

    formHasChanged = (ev: ChangeEvent<HTMLInputElement>) => {
        this.setState({ filter: ev.target.value });
    }

    async goBack() {
        await this.context.actions!.selectSchedule(undefined);
        await this.context.actions!.selectScheduleName(undefined);
        this.props.history.goBack();
    }

    next() {
        this.props.history.push(getNextRoute(this.context));
    }

    selectQueue(selected?: string) {
        this.setState({ selectedSchedule: selected });
        this.context.actions!.selectScheduleName(selected);
        if (this.context.state.selectedBranch) {
            const schedule = this.state.schedules
                .find(s => s.name === selected);
            this.context.actions!.selectSchedule(schedule);
        } else {
            this.context.actions!.selectSchedule(undefined);
        }
    }

    validateCompany() {
        const { t } = this.context;
        const ctxCompany = this.context.state.company;
        if (!ctxCompany || ctxCompany.name !== this.props.params.companyName) {
            console.error("No company was selected in SelectSchedule");
            if (!this.props.params.companyName)
                toast.error(t("SelectBranch.TOAST_COMPANY_NOT_SELECTED"));
            return <Navigate to={`/company/${this.props.params.companyName || ''}`}/>;
        }
    }

    async componentDidMount() {
        const { company } = this.context.state;
        if (!company) return;

        const allSchedules = (await api.schedules().getAll(company.id)).data as Schedule[];
        orderArray(allSchedules, "name");
        const selectedBranch = this.context.state.selectedBranch;

        const schedules = !selectedBranch || !selectedBranch.id ? allSchedules :
            allSchedules.filter(s => s.branches.some(b => b.id === selectedBranch.id));

        const scheduleNames = new Set<string>(schedules.map(s => s.name));

        const selectedSchedule = this.context.state.selectedScheduleName;

        this.setState({
            schedules,
            scheduleNames
        }, () => {
            this.selectQueue(selectedSchedule);
        });
    }

    componentDidUpdate() {
        if (this.validateCompany()) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
        }
    }

    render() {
        const companyValidationErr = this.validateCompany();
        if (companyValidationErr) return companyValidationErr;

        const { scheduleNames, selectedSchedule, filter } = this.state;
        const currentRouteData = getCurrentData(this.context, this.props.location.pathname);
        const currIdx = currentRouteData ? currentRouteData.idx : 0;

        return (
            <AppConsumer>
                {({ state, t }) => (
                    <>
                        <div className="list-selection form-card__content">
                            <div className="list-selection__search input-container__search">
                                <input type="text" placeholder={t("SelectSchedule.PLACEHOLDER_SEARCH")} className="input-field__search" onChange={this.formHasChanged} />
                            </div>
                            <div className="list-selection__content">
                                <ul className="list-selection__content-list form-card__list">
                                    {Array.from(scheduleNames)
                                        .filter(schName => schName.toLowerCase().includes(filter.toLowerCase()))
                                        .map((schName: string, i: number) => {
                                            return (
                                                <li key={i} className={
                                                    "list-selection__content-item form-card__item" +
                                                    (schName === selectedSchedule ? " list-selection__content-item-active form-card__item--active" : "")
                                                } onClick={this.selectQueue.bind(this, schName)}>
                                                    {schName}
                                                    {
                                                        !this.context.state?.company?.retroStyle &&
                                                        <span className="list-selection__content-item_radio"><i className="bi bi-check-lg"></i></span>
                                                    }
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                        <div className="list-selection__buttons form-card__actions">
                            <TrackedButton
                                id="backToNewSchedules"
                                className={`list-selection__button${currIdx > 1 ? '' : '__hidden'} list-selection__button-blue form-card__button form-card__button--previous`}  onClick={this.goBack}>{t("SelectSchedule.PREVIOUS")}
                            </TrackedButton>
                            <TrackedButton
                                id="next_schedule"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next"
                                disabled={!state.selectedScheduleName}
                                onClick={this.next}
                            >
                                {t("SelectSchedule.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        )
    }

    static contextType = AppContext
    declare context: React.ContextType<typeof AppContext>;
}

export default withRouteProps(withTracker(SelectSchedule));

