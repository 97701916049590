import {EModality, IContext, IContextState} from "../interfaces/context";
import { validateCustomer } from "./CustomerUtils";
import {isEmpty} from "./ObjectUtils"

export interface IRoute {
    path: (companyName: string) => string,
    checkIfNecessary: (state: IContextState) => boolean,
    title: string,
    retroTitle: string,
    icon: string,
    reset: (ctx: IContext) => void,
    canBeReset: (ctx: IContext) => boolean,
    countStep: boolean
}

type IRouteOrder = Readonly<IRoute[]>;

const branchesRoute: IRoute = {
    path: cn => `/company/${cn}/branches`,
    checkIfNecessary: state => isEmpty(state.selectedBranch),
    title: "RouteHelper.SELECT_BRANCH",
    retroTitle: "RouteHelper.RETRO_TITLE_BRANCH",
    icon:"bi-house",
    reset: ctx => ctx.actions!.selectBranch(undefined),
    canBeReset: ctx => !ctx.state.preventReset?.branch,
    countStep: true
};

const schedulesRoute: IRoute = {
    path: cn => `/company/${cn}/schedule`,
    checkIfNecessary: state => isEmpty(state.selectedScheduleName),
    title: "RouteHelper.SELECT_SCHEDULE",
    retroTitle: "RouteHelper.RETRO_TITLE_SCHEDULE",
    icon:"bi-card-list",
    reset: ctx => ctx.actions!.selectSchedule(undefined),
    canBeReset: ctx => !ctx.state.preventReset?.schedule,
    countStep: true
};

const selectModality: IRoute = {
    path: cn => `/company/${cn}/modality`,
    checkIfNecessary: state => !!state.company?.selectableModality &&
        state.modality === undefined &&
        !state.selectedBranch &&
        !state.dateTime,
    title: "RouteHelper.SELECT_SEARCH_MODE",
    retroTitle: "RouteHelper.RETRO_TITLE_MODE",
    icon:"",
    reset: ctx => ctx.actions!.selectModality(undefined),
    canBeReset: () => true,
    countStep: true
}

const dateRoute: IRoute = {
    path: cn => `/company/${cn}/date`,
    checkIfNecessary: state => !state.dateTime,
    title: "RouteHelper.SELECT_DATE",
    retroTitle: "RouteHelper.RETRO_TITLE_DATE",
    icon:"bi-calendar-week",
    reset: ctx => !ctx.state.preventReset?.dateTime && ctx.actions!.selectDate(undefined),
    canBeReset: ctx => !ctx.state.preventReset?.dateTime,
    countStep: true
};

const formRoute: IRoute = {
    path: cn => `/company/${cn}/form`,
    checkIfNecessary: state => !state.customer || validateCustomer(state.customer, state.obligatoryFields).length>0,
    title: "RouteHelper.DATA_FORM",
    retroTitle: "RouteHelper.RETRO_TITLE_FORM",
    icon:"bi-person-lines-fill",
    reset: ctx => ctx.actions!.selectCustomer(undefined),
    canBeReset: () => true,
    countStep: true
};

const confirmRoute: IRoute = {
    path: cn => `/company/${cn}/confirm`,
    checkIfNecessary: state => !state.confirmationType,
    title: "RouteHelper.CONFIRMATION",
    retroTitle: "RouteHelper.RETRO_TITLE_CONFIRMATION",
    icon:"bi-check-circle",
    reset: ctx => ctx.actions!.setConfirmationType(undefined),
    canBeReset: () => true,
    countStep: true
};

const reservedRoute: IRoute = {
    path: cn => `/company/${cn}/done`,
    checkIfNecessary: () => true,
    title: "RouteHelper.APPOINTMENT_BOOKED",
    retroTitle: "RouteHelper.RETRO_TITLE_APPOINTMENT_BOOKED",
    icon:"bi-check-circle",
    reset: () => {},
    canBeReset: () => true,
    countStep: false
}

const orders:Readonly<{[name:string]:IRouteOrder}> = {
    branchesFirst: [branchesRoute, schedulesRoute, dateRoute, formRoute, confirmRoute, reservedRoute],
    schedulesFirst: [schedulesRoute, branchesRoute, dateRoute, formRoute, confirmRoute, reservedRoute],
    schedulesFirstWithModality: [schedulesRoute, selectModality, branchesRoute, dateRoute, formRoute, confirmRoute, reservedRoute],
    dateFirstWithModality: [schedulesRoute, selectModality, dateRoute, branchesRoute, formRoute, confirmRoute, reservedRoute]
};

export const getOrder = (ctx: IContext): IRouteOrder => {
    const {company, modality} = ctx.state;
    if (!company || !company?.id) throw new Error("Company required to choose next route");
    if (company.showBranchFirst)
        return orders.branchesFirst;
    if(!company.selectableModality)
        return orders.schedulesFirst;
    if(modality === undefined || modality === EModality.branchesFirst)
        return orders.schedulesFirstWithModality;
    return orders.dateFirstWithModality;
};

export const getNextRoute = (ctx: IContext) => {
    let nextRoute;
    const order = getOrder(ctx);

    for(const r of order){
        if(!nextRoute && r.checkIfNecessary(ctx.state))
            nextRoute = r;
        if(nextRoute && r.canBeReset(ctx))
            r.reset(ctx);
    }

    console.debug('nextRoute', nextRoute);

    if (!nextRoute)
        return '/';
    return nextRoute.path(ctx.state.company?.name as string);
};

export const getCurrentData = (ctx: IContext, currentPath: string): { idx: number, title: string, retroTitle: string, icon:string, countStep:boolean } | undefined => {
    const order = getOrder(ctx);
    const companyName = ctx.state.company?.name as string;
    let idx = 1;
    for (const route of order) {
        if(route.path(companyName) === decodeURI(currentPath)){
            if(!route.countStep)
                return undefined;
            return {
                idx,
                title: route.title,
                icon: route.icon,
                retroTitle: route.retroTitle,
                countStep: route.countStep
            }
        }
        if(route.countStep)
            idx++;
    }
    return undefined;
};

export const getTotalStepCount = (ctx: IContext): number => {
    const order = getOrder(ctx);
    return order.reduce((acum, route) => acum + ( route.countStep ? 1 : 0 ), 0);
};

export const resetAll = (ctx: IContext) => {
    const order = orders.schedulesFirstWithModality;
    order.forEach(r => r.reset(ctx));
};

export const routePrefix = import.meta.env.DEV ? "/" : "/#/";

