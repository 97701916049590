import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppConsumer, AppContext } from "../components/AppContext";
import { resetAll } from "../util/RouteHelper";
import error from '../img/404.png';


const NotFound = () => {
    const ctx = useContext(AppContext);
    useEffect(() => {
        resetAll(ctx);
    }, []);

    return (
        <AppConsumer>
            {({t}) => (
                <>
                    <div className="errorNotFound">
                        <img src={error} className="errorNotFound_img" alt="Fecha" />
                        <p>
                            {t("NotFound.NOT_FOUND")}<br/>{t("NotFound.TRY_AGAIN")}
                        </p>
                        {
                            // Sólo muestro el link en el ambiente de desarrollo,
                            // la idea es que los clientes no puedan llegar a esa vista
                            import.meta.env.DEV &&
                                <Link to="/companies"> {t("NotFound.COMPANIES_LIST")} </Link>
                        }
                    </div>
                </>
            )}
        </AppConsumer>
    )
}

export default NotFound;
