import React from "react";
import {AppConsumer, AppContext} from "../components/AppContext";
import Calendar from "react-calendar";
import {IGenericProps} from "../interfaces/generics";
import api from "../requests/api";
import dayjs from "dayjs";
import {Slot} from "../interfaces/models";
import {getNextRoute} from "../util/RouteHelper";
import {toast} from "react-toastify";
import * as Analytics from '../util/Analytics';
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import {OnArgs, TileArgs, Value} from "react-calendar/dist/cjs/shared/types";
import withRouteProps from "../util/withRouteProps";
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { compareTimeZones } from "../util/TimeZoneUtils";

dayjs.extend(tz);
dayjs.extend(utc);
type hour = { show: string, slot: Slot };

interface ICalendarView {
    date?: Date,
    hours?: hour[],
    showHours: boolean,
    selectedHour?: hour,
    availability: Slot[],
    defaultActiveStartDate?: Date,
    dayWithSlots: { [key: number]: boolean },
    timeToNextSlot: number,
    timezone?: string,
    timeZoneWarningDisplayed: boolean;
}

class CalendarView extends React.Component<IGenericProps, ICalendarView> {
    constructor(props: IGenericProps) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.next = this.next.bind(this);
        this.selectHour = this.selectHour.bind(this);
        this.dateDisabled = this.dateDisabled.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onActiveStartDateChange = this.onActiveStartDateChange.bind(this);
        this.state = {
            date: undefined,
            hours: [],
            showHours: false,
            selectedHour: undefined,
            availability: [],
            defaultActiveStartDate: undefined,
            dayWithSlots: {},
            timeToNextSlot: 0,
            timezone: 'America/Argentina/Buenos_Aires',
            timeZoneWarningDisplayed: false
        };
    }


    async onChange(value: Value) {
        if (!value || value.constructor !== Date) return;
        if (this.state.date && dayjs.utc(value).tz(this.state.timezone).isSame(this.state.date, 'day'))
            return;

        await this.context.actions!.selectDate(undefined);
        this.setState({
            date: value,
            showHours: true,
            hours: this.computeHours(value),
            selectedHour: undefined
        });
        Analytics.event("day_calendar");
    }

    async onActiveStartDateChange({view, activeStartDate}: OnArgs) {
        await new Promise<void>((res) => this.setState({dayWithSlots: {}}, res))
        if (view !== "month" || !activeStartDate) return;
        await this.getAvailability(dayjs(activeStartDate).add(1, 'day').toDate());
    }

    selectHour(selected: hour) {
        this.setState({selectedHour: selected});
        Analytics.event("hour_calendar");
        this.context.actions!.selectDate(new Date(selected.slot.zonedStartDate));       
    }

    async goBack() {
        await this.context.actions!.selectDate(undefined);
        this.props.history.goBack();
    }

    next() {
        this.props.history.push(getNextRoute(this.context));
    }

    async getAvailability(date: Date) {
        const {selectedSchedule, selectedBranch, selectedScheduleName, company} = this.context.state;
        const {t} = this.context;
        if (!company || company.name !== this.props.params.companyName) {
            console.error("No company was selected in CalendarView");
            if (!this.props.params.companyName)
                toast.error(t("CalendarView.TOAST_NO_COMPANY_SELECTED"));
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }
        if (!selectedScheduleName || (selectedBranch && !selectedSchedule)) {
            console.error("No schedule was selected in CalendarView");
            toast.error(t("CalendarView.TOAST_NO_SCHEDULE_SELECTED"));
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }
        const dateStr = dayjs.utc(date).tz(this.state.timezone).format('YYYY-MM-DDTHH:mm:ssZZ');
        let res;
        if (selectedBranch && selectedSchedule)
            res = (await api.availability().getInMonth(selectedSchedule.id, selectedBranch.id, dateStr)).data as { slots: Slot[] };
        else
            res = (await api.availability().getInMonthForAllBranches(company.name, selectedScheduleName, dateStr)).data as { slots: Slot[] };

        const availability = res.slots.filter(slot => slot.availability > 0);
        this.setState({availability})
        this.checkTimezone(availability);
        this.computeDaysWithSlots(date, availability);
        return availability;
    }

    computeDaysWithSlots(initialDate: Date, availability: Slot[]) {
        const dayWithSlots: { [key: number]: boolean } = {};
        availability.forEach((slot: Slot) => {
            const slotDate = dayjs.utc(slot.zonedStartDate).tz(this.state.timezone);
            if (slotDate.isSame(initialDate, 'month'))
                dayWithSlots[slotDate.date()] = true;
        });
        this.setState({dayWithSlots});
    }

    dateDisabled({date, view, activeStartDate}: TileArgs): boolean {
        if (this.state.date && dayjs(date).isSame(this.state.date, 'day')) {
            return false;
        } else if (view !== "month") {
            return false;
        } else if (!dayjs(date).isSame(activeStartDate, 'month')) {
            return true;
        }

        const tileDate = dayjs(date);
        return !this.state.dayWithSlots[tileDate.date()];
    }

    computeHours(date: Date): hour[] {
        const {selectedSchedule} = this.context.state;
        const month = date.getMonth(), day = date.getDate();
        const availabilityInDate = this.state.availability.filter(slot => {
            const dayjsStartDate = dayjs.utc(slot.zonedStartDate).tz(this.state.timezone);
            return dayjsStartDate.date() === day &&
                dayjsStartDate.month() === month;
        });
        const hours = availabilityInDate
            .filter(slot => {
                return !selectedSchedule || (selectedSchedule && !selectedSchedule.timeToNextSlot) ||
                    (selectedSchedule && dayjs.utc().tz(this.state.timezone).add(selectedSchedule.timeToNextSlot, "minutes").isBefore(dayjs.utc(slot.zonedStartDate).tz(this.state.timezone)));
            })
            .map(slot => {
                return {
                    show: dayjs.utc(slot.zonedStartDate).tz(this.state.timezone).format("LT"), // Locale Time format
                    slot
                } as hour;
            });
        return hours;
    }

    async componentDidMount() {
        let dateToSearch = new Date();
        const tries = 7;
        let timezone;
        if(this.context.state.selectedBranch?.id){
            timezone = (await api.timezone().get(this.context.state.selectedBranch?.id || 0)).data;
        } else {
            timezone = (await api.timezone().company(this.context.state.company?.id || 0)).data;
        }
        this.context.actions!.setTimezone(timezone);
        for (let i = 0; i < tries; ++i) {
            const availability = await this.getAvailability(dateToSearch);
            if (!availability) {
                // There was an error, will redirect
                return;
            } else if (availability.length > 0) {
                // Some availability found
                dateToSearch = dayjs.utc(availability[0].startDate).tz(this.state.timezone).toDate();
                this.setState({defaultActiveStartDate: dateToSearch, timezone: timezone})
                return;
            }
            // Did not find anything this month, trying next month
            dateToSearch = dayjs.utc(dateToSearch).tz(timezone).add(1, "month").toDate()
        }
        this.context.actions!.selectDate(undefined);
    }

    componentDidUpdate(prevProps: IGenericProps, prevState: ICalendarView) {
        const ctxCompany = this.context.state.company;
        if (ctxCompany?.name !== this.props.params.companyName) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
            return;
        }
        if (this.state.timezone !== prevState.timezone) {
            this.checkTimezone(this.state.availability);
        }
    }

    displayTimeZoneWarning(availability: Slot[]) {
        const userTimezone = dayjs.tz.guess();
        if(!this.state.timezone ) return true;
        if (this.state.timezone ===userTimezone) return false;
        if(!Array.isArray(availability)) return true;
        const startCompareDate = availability[0]?.zonedStartDate;
        const endCompareDate = availability[availability.length -1]?.zonedStartDate;
        if(!startCompareDate || !endCompareDate) return true;
        return !compareTimeZones(this.state.timezone, userTimezone, startCompareDate, endCompareDate);
    }

    checkTimezone(availability: Slot[]) {
        const warn= this.displayTimeZoneWarning(availability)
        this.setState( {timeZoneWarningDisplayed: warn});

    }

    render() {
        const {hours, selectedHour, defaultActiveStartDate} = this.state;

        return (
            <AppConsumer>
                {({state, t}) => (
                    <>
                        <div className="list-selection list-selection__calendar form-card__content">
                            <div className="list-selection_calendar">
                                <Calendar onChange={this.onChange}
                                    tileDisabled={this.dateDisabled}
                                    onActiveStartDateChange={this.onActiveStartDateChange}
                                    defaultActiveStartDate={defaultActiveStartDate}
                                    locale={dayjs().utc().tz(this.state.timezone).locale()}
                                />
                            </div>
                            {
                                this.state.showHours &&
                                <div className="list-selection__hours">
                                    <div className="list-selection__hours-list">
                                        {hours?.map((h: hour, index: number) => {
                                            return (
                                                <li key={index} className={
                                                    "list-selection__hours-list-selecthour" +
                                                    (h === selectedHour ? " list-selection__hours-list-selecthour-active" : "")}
                                                onClick={this.selectHour.bind(this, h)}>
                                                    {h.show}
                                                </li>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                        { this.state.timezone && this.state.timeZoneWarningDisplayed &&
                        <div>
                            {t("CalendarView.NOT_SAME_TIMEZONES", {timezone: this.state.timezone})}
                        </div>}
                        <div className="list-selection__buttons">
                            <button
                                id="backToScheduleFirst"
                                className="list-selection__button list-selection__button-blue"
                                onClick={this.goBack}>

                                {t("CalendarView.PREVIOUS")}
                            </button>
                            <TrackedButton
                                id="next_calendar"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next"
                                onClick={this.next} disabled={!state.dateTime}
                                extraData={{date: selectedHour?.slot.zonedStartDate, hour: selectedHour?.show}}>
                                {t("CalendarView.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        );
    }

    static contextType = AppContext
    declare context: React.ContextType<typeof AppContext>;
}

CalendarView.contextType = AppContext;

export default withRouteProps(withTracker(CalendarView));
