import { createBrowserRouter, createHashRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import SelectSchedule from "./views/SelectSchedule";
import SelectModality from "./views/SelectModality";
import SelectBranch from "./views/SelectBranch";
import CalendarView from "./views/CalendarView";
import ClientForm from "./views/ClientForm";
import ConfirmView from "./views/ConfirmView";
import Steps from "./components/Steps"
import Redirector from "./views/Redirector";
import SelectCompany from './views/SelectCompany';
import Reserved from './views/Reserved';
import NotFound from './views/NotFound';
import LanguageSelector from "./components/LanguageSelector";

function Root() {
    return <>
        <Steps />
        <div className="content-box form-card">
            <Outlet />
        </div>
        <LanguageSelector />
    </>;
}

const createRouter = import.meta.env.DEV ?
    createBrowserRouter :
    createHashRouter;

export const router = createRouter(
    createRoutesFromElements(<Route element={<Root />}>
        {/* All old format URLs will go through the redirector, for compatibility reasons */}
        {[
            "company/:companyName",
            "company/:companyName/getAppointments",
            "company/:companyName/branchesView/:branch/scheduleView",
            "company/:companyName/branchesView",
            "company/:companyName/schedules",
            "company/:companyName/scheduleView",
            "company/:companyName/schedules/:schedule/branches",
            "company/:companyName/scheduleView/:schedule/branchesView",
            "company/:companyName/schedules/:schedule/branches/:branch/days",
            "company/:companyName/branchesView/:branch/scheduleView/:schedule/daysView",
            "company/:companyName/scheduleView/:schedule/branchesView/:branch/daysView",
            "company/:companyName/schedules/:schedule/branches/:branch/days/:date/customerInfo",
            "company/:companyName/branchesView/:branch/scheduleView/:schedule/daysView/:date/customerInfoView",
            "company/:companyName/scheduleView/:schedule/branchesView/:branch/daysView/:date/customerInfoView"
        ].map(path =>
            <Route key={path} path={path} element={<Redirector />} />)}
        <Route path="company/:companyName/schedule" element={<SelectSchedule />} />
        <Route path="company/:companyName/modality" element={<SelectModality />} />
        <Route path="company/:companyName/branches" element={<SelectBranch />} />
        <Route path="company/:companyName/date" element={<CalendarView />} />
        <Route path="company/:companyName/form" element={<ClientForm />} />
        <Route path="company/:companyName/confirm" element={<ConfirmView />} />
        <Route path="company/:companyName/done" element={<Reserved />} />
        <Route path="companies" element={<SelectCompany />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to='/404' />} />
    </Route>
    )
);

