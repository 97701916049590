import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Events } from '../interfaces/events';
import TrackedButton from "./TrackedButton";

interface IModalState{
    animation: 'open'|'close';
}

export enum EModalTypes {
    WARN = 'WARN',
    PERSUADE = 'PERSUADE',
    ACCEPT = 'ACCEPT'
}

interface IModalProps{
    onConfirm: () => unknown;
    onCancel: () => unknown;
    confirmId?: Events;
    cancelId?: Events;
    type: EModalTypes;
    title: string;
    description: string;
    primaryLabel: string;
    secondaryLabel: string;
    useMarkdown: boolean;
}

export default class ModalDialog extends React.Component<IModalProps, IModalState> {
    constructor(props: IModalProps) {
        super(props);

        this.state = {
            animation: 'open'
        };
    }
    public static defaultProps = {
        primaryLabel: "Si",
        secondaryLabel: "No",
        useMarkdown: false
    }
    readonly ref: React.RefObject<HTMLDivElement> = React.createRef();

    clickHandle = (ev: MouseEvent) => {
        const cur = this.ref.current;
        if (cur && !cur.contains(ev.target as Node)) {
            this.closeDialog(this.props.onCancel);
        }
    }

    componentDidMount = () => {
        document.addEventListener("mousedown", this.clickHandle);
    }

    componentWillUnmount = () => {
        document.removeEventListener("mousedown", this.clickHandle);
    }

    closeDialog(callback: ()=>unknown, time = 600, ev ?: React.MouseEvent<HTMLElement, MouseEvent>) {
        ev?.stopPropagation();
        this.setState({animation: 'close'});
        if (typeof callback === "function") {
            setTimeout(callback, time);
        }
    }

    render() {
        let secondaryButtonType = 'cancel';
        let primaryButtonType = 'confirm';
        if (this.props.type === EModalTypes.WARN) {
            primaryButtonType = 'cancel';
            secondaryButtonType = 'subtle';
        } else if (this.props.type === EModalTypes.PERSUADE) {
            primaryButtonType = 'confirm';
            secondaryButtonType = 'subtle';
        } else if (this.props.type === EModalTypes.ACCEPT) {
            primaryButtonType = 'confirm';
            secondaryButtonType = 'hidden';
        }
        return (
            <div className={`modal-dialog__background modal-dialog--${this.state.animation}`}>
                <div ref={this.ref} className={`modal-dialog__container`}>
                    <div className="modal-dialog__main-container">
                        <h1 className="modal-dialog__title">
                            {this.props.title}
                        </h1>
                        <div className="modal-dialog__content">
                            {
                                this.props.useMarkdown ?
                                    <div style={{textAlign: 'left'}}>
                                        <ReactMarkdown>
                                            {this.props.description}
                                        </ReactMarkdown>
                                    </div>
                                    :
                                    <p className="modal-dialog__info">
                                        this.props.description
                                    </p>
                            }
                        </div>
                    </div>
                    <div className="modal-dialog__buttons-container">
                        <TrackedButton className={`modal-dialog__button modal-dialog__${secondaryButtonType}-button`}
                            id={this.props.cancelId}
                            style={{cursor: 'pointer'}}
                            onClick={this.closeDialog.bind(this, this.props.onCancel, 600)}>
                            {this.props.secondaryLabel}
                        </TrackedButton>
                        <TrackedButton className={`modal-dialog__button modal-dialog__${primaryButtonType}-button`}
                            id={this.props.confirmId}
                            style={{cursor: 'pointer'}}
                            onClick={this.closeDialog.bind(this, this.props.onConfirm, 300)}>
                            {this.props.primaryLabel}
                        </TrackedButton>
                    </div>
                </div>
            </div>
        );
    }
}

