/**
 * Adapted from https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { useEffect } from "react";
import { IGenericProps } from "../interfaces/generics";
import * as Analytics from './Analytics';

const withTracker = <T extends IGenericProps>(WrappedComponent: React.ComponentType<T>) => {
    const trackPage = (page: string) => {
        Analytics.pageChange(page);
    };

    const HigherOrderComponent = (props: T) => {
        useEffect(() => {if(props.location !== undefined) trackPage(props.location.pathname)} , [
            props.location
        ]);

        return <WrappedComponent {...props} />;
    };

    return HigherOrderComponent;
};

export default withTracker;
