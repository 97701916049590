import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IGenericProps, IParams } from "../interfaces/generics";

const withRouteProps = <T extends Record<string, unknown>>(WrappedComponent: React.ComponentType<T & IGenericProps>) => {

    const HigherOrderComponent = (props: T) => {
        const params = useParams<IParams>();
        const location = useLocation();
        const navigate = useNavigate();
        const history = {
            push: navigate,
            goBack: navigate.bind(window, -1)
        };

        return <WrappedComponent {...props} params={params} location={location} history={history} />;
    };

    return HigherOrderComponent;
};

export default withRouteProps ;

