import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppProvider} from './components/AppContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './util/i18n'
import ViewLoader from './components/ViewLoader';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Suspense fallback={<ViewLoader loading={true} fullscreen={true}/>}>
            <I18nextProvider i18n={i18n}>
                <AppProvider>
                    <App/>
                </AppProvider>
            </I18nextProvider>
        </Suspense>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
