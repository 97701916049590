import React, { ChangeEvent } from "react";
import { AppConsumer, AppContext } from "../components/AppContext";
import { IGenericProps } from "../interfaces/generics";
import { getCurrentData, getNextRoute } from "../util/RouteHelper";
import { isEmpty, orderArray } from "../util/ObjectUtils";
import api from "../requests/api";
import { Branch, Schedule } from "../interfaces/models";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import withTracker from "../util/withTracker";
import TrackedButton from "../components/TrackedButton";
import withRouteProps from "../util/withRouteProps";
import { Navigate } from "react-router-dom";

interface IBranchesState {
    branches: Branch[],
    selectedBranch?: Branch,
    schedules: Schedule[],
    filter: string
}

class SelectBranch extends React.Component<IGenericProps, IBranchesState>{
    constructor(props: IGenericProps) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.selectBranch = this.selectBranch.bind(this);
        this.next = this.next.bind(this);
        this.state = {
            branches: [],
            selectedBranch: undefined,
            schedules: [],
            filter: ''
        }
    }

    formHasChanged = (ev: ChangeEvent<HTMLInputElement>) => {
        this.setState({ filter: ev.target.value });
    }

    async goBack() {
        await this.context.actions!.selectBranch(undefined);
        this.props.history.goBack();
    }

    selectBranch(selected: Branch) {
        this.setState({ selectedBranch: selected });
        this.context.actions!.selectBranch(selected);

        const sched = this.state.schedules.find(s => {
            return s.branches.some(b => b.id === selected.id);
        });
        this.context.actions!.selectSchedule(sched);
    }

    next() {
        this.props.history.push(getNextRoute(this.context));
    }

    async fetchBranches(schedules: Schedule[], scheduleName?: string, date?: Date): Promise<Branch[]> {
        const companyName = this.context.state.company?.name as string;
        if (!date) {
            return schedules.reduce((acc: Branch[], s: Schedule) => {
                for (const b of s.branches) {
                    if (!acc.some(b2 => b.id === b2.id))
                        acc.push(b);
                }
                return acc;
            }, []);
        }
        return (await api.branches().byScheduleAndDate(companyName, scheduleName, dayjs(date).format('YYYY-MM-DDTHH:mm:ssZZ'))).data as Branch[];
    }

    validateCompany() {
        const { t } = this.context;
        const ctxCompany = this.context.state.company;
        if (!ctxCompany || ctxCompany.name !== this.props.params.companyName) {
            console.error("No company was selected in SelectBranch");
            if (!this.props.params.companyName)
                toast.error(t("SelectBranch.TOAST_COMPANY_NOT_SELECTED"));
            return <Navigate to={`/company/${this.props.params.companyName || ''}`}/>;
        }
    }

    async componentDidMount() {
        const { company } = this.context.state;
        if (!company) return;

        let schedules = (await api.schedules().getAll(company.id)).data as Schedule[];

        if (this.context.state.selectedScheduleName) {
            schedules = schedules.filter(s => s.name === this.context.state.selectedScheduleName);
        }

        const branches = await this.fetchBranches(schedules, this.context.state.selectedScheduleName, this.context.state.dateTime);
        orderArray(branches, "name");

        const selectedBranch = this.context.state.selectedBranch ?
            branches.find(b => b.id === this.context.state.selectedBranch?.id) :
            undefined;
        this.setState({
            branches,
            selectedBranch,
            schedules
        });
    }

    componentDidUpdate() {
        if (this.validateCompany()) {
            console.warn("Company changed, redirecting");
            this.props.history.push(`/company/${this.props.params.companyName || ''}`);
        }
    }

    render() {
        const companyValidationErr = this.validateCompany();
        if (companyValidationErr) return companyValidationErr;

        const { branches, selectedBranch, filter } = this.state;
        const currentRouteData = getCurrentData(this.context, this.props.location.pathname);
        const currIdx = currentRouteData ? currentRouteData.idx : 0;
        return (
            <AppConsumer>
                {({ state, t }) => (
                    <>
                        <div className="list-selection form-card__content">
                            <div className="list-selection__search">
                                <input type="text" placeholder={t("SelectBranch.PLACEHOLDER_SEARCH")} className="input-field__search" onChange={this.formHasChanged} />
                            </div>
                            <div className="list-selection__content">
                                <ul className="list-selection__content-list">
                                    {branches
                                        .filter(b => b.name.toLowerCase().includes(filter.toLowerCase()))
                                        .map((branch: Branch, i: number) => {
                                            return (
                                                <li key={i} className={
                                                    "list-selection__content-item" +
                                                    (branch === selectedBranch ? " list-selection__content-item-active" : "")
                                                } onClick={this.selectBranch.bind(this, branch)}>
                                                    {branch.name}
                                                    {
                                                        !this.context.state.company?.retroStyle &&
                                                        <span className="list-selection__content-item_radio"><i className="bi bi-check-lg"></i></span>
                                                    }

                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                        <div className="list-selection__buttons">
                            <TrackedButton
                                id="backToNewBranch"
                                className={`list-selection__button${currIdx > 1 ? '' : '__hidden'} list-selection__button-blue`} onClick={this.goBack}>{t("SelectBranch.PREVIOUS")}
                            </TrackedButton>
                            <TrackedButton
                                id="next_branch"
                                className="list-selection__button list-selection__button-blue form-card__button form-card__button--next" disabled={isEmpty(state.selectedBranch)} onClick={this.next}>{t("SelectBranch.NEXT")}
                            </TrackedButton>
                        </div>
                    </>
                )}
            </AppConsumer>
        )
    }

    static contextType = AppContext
    declare context: React.ContextType<typeof AppContext>;
}

export default withRouteProps(withTracker(SelectBranch));

