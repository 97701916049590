import {Branch, Company, Customer, CustomerFields, ReasonOption, Schedule} from "./models";

export enum EModality {
    branchesFirst,
    dateFirst
}

export type ConfirmationType = "EMAIL" | "SMS" | "NONE";

export interface IContextState {
    company?: Company,
    selectedSchedule?: Schedule,
    selectedScheduleName?: string,
    selectedBranch?: Branch,
    dateTime?: Date,
    customer?: Customer,
    obligatoryFields?: CustomerFields
    modality?: EModality,
    confirmationType?: ConfirmationType,
    reason?: string,
    reasonChosenOptions?: ReasonOption[],
    timezone?: string,
    randomFileId?: string,
    preventReset?: {
        schedule: boolean,
        branch: boolean,
        dateTime: boolean,
    },
}

export interface IContext {
    state: IContextState,
    actions?: {
        getBackgroundUrl(): Promise<string>,
        getCustomCss(): Promise<string>,
        selectCompany(selected?: Company): Promise<void>,
        selectSchedule(selected?: Schedule): Promise<void>,
        selectScheduleName(selected?: string): Promise<void>,
        selectBranch(selected?: Branch): Promise<void>,
        selectDate(selected?: Date): Promise<void>,
        selectCustomer(selected?: Customer): Promise<void>,
        updateState(newState: IContextState, cb?: (arg: IContextState) => void): Promise<IContextState>,
        setObligatoryFields(selected: CustomerFields): Promise<void>,
        selectModality(selected?: EModality): Promise<void>,
        setConfirmationType(selected?: ConfirmationType): Promise<void>,
        setRandomFileId(selected?: string): Promise<void>,
        setReason(selected?: string): Promise<void>,
        setReasonChosenOptions(selected?: ReasonOption[]): Promise<void>,
        setTimezone(selected?: string): Promise<void>,
        getLogoUrl(): Promise<string>,
        clear(): Promise<IContextState>,
    },
    t: ((s: string, o?: Record<string, string>) => string),
}
