import TagManager from 'react-gtm-module'
import trackedEvents from './trackingData/trackedEvents.json'
import trackedPages from './trackingData/trackedPages.json'
import {Events} from "../interfaces/events";


let initialized:boolean = false;
let onInitCallbacks:(() => void)[] = [];


const initialize = (trackingId:string)=>{
    if(initialized) return;

    TagManager.initialize({gtmId: trackingId});
    initialized=true;
    onInitCallbacks.forEach(cb => cb());
    onInitCallbacks = [];
};

const deinitialize = () => {
    initialized = false;
};

const event = (evName: Events, extraData?: Record<string, unknown>) => {
    if(!initialized) {
        onInitCallbacks.push(event.bind(window, evName, extraData));
        return;
    }

    const trackingInfo = trackedEvents[evName];
    if (!trackingInfo || !trackingInfo.track)
        return;

    console.debug(evName, "being tracked as", trackingInfo.eventAction);

    TagManager.dataLayer({
        dataLayer:{
            eventCategory: trackingInfo.eventCategory,
            eventAction: trackingInfo.eventAction,
            eventLabel: trackingInfo.eventLabel,
            eventValue: '',
            event: 'eventClick',
            extraData
        },
    });
};

const pageChange = (page:string) =>{
    if(!initialized) {
        onInitCallbacks.push(pageChange.bind(window, page));
        return;
    }

    const trackingInfo = trackedPages.find(p => {
        return new RegExp("^" + p.pattern + "$").test(page);
    });
    if(!trackingInfo || !trackingInfo.track) return;
    console.debug(page, "being tracked as", trackingInfo.name);

    TagManager.dataLayer({
        dataLayer:{
            eventCategory: trackingInfo.eventCategory,
            eventAction: 'Pageview',
            eventLabel: trackingInfo.eventLabel,
            eventValue: '',
            event: 'eventClick'
        }
    });
};


export {initialize, deinitialize, event, pageChange};